import React from "react";
import CommonLayout from "../../components/Layouts/CommonLayout";
import ValueAddedServices from "../../components/ValueAddedServices";

const ValueAddedServicesPage = () => {
  return (
    <CommonLayout>
      <ValueAddedServices />
    </CommonLayout>
  );
};

export default ValueAddedServicesPage;
