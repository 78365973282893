import React from "react";
import Style from "../MoneyExchange.module.scss";
import Assets from "../../Layouts/Assets";
import { useDimensions } from "../../../logic/Dimensions";
import { useSelector } from "react-redux";

const ExchangeContent = ({ mobileData }) => {
  const { width } = useDimensions();
  const { activeClass } = useSelector((state) => state.mobile);

  return (
    <>
      <div
        className={
          activeClass ? `${Style.cntwrap} ${Style.loaded}` : Style.cntwrap
        }
      >
        <h2>{mobileData?.details["app-section-main-title"]}</h2>
        <div className={Style.shrtcnt}>
          <p>{mobileData?.details["app-section-main-description"]}</p>
        </div>
      </div>
      <div className={Style.dwnldapp}>
        {activeClass && <header>Download App</header>}
        {activeClass && (
          <ul className={Style.appstore}>
            <li>
              <a>
                {width > 1200 ? (
                  <>
                    <img
                      src={
                        mobileData?.details["app-section-app-store-qr-code"]
                          ?.url
                      }
                    />
                    <img
                      src={
                        mobileData?.details["app-section-app-store-logo-url"]
                          ?.url
                      }
                    />
                  </>
                ) : (
                  <img className={Style.mble} src={Assets.applestoremble} />
                )}
              </a>
            </li>
            <li>
              {activeClass && (
                <a>
                  {width > 1200 ? (
                    <>
                      <img
                        src={
                          mobileData?.details[
                            "app-section-app-google-play-qr-code"
                          ]?.url
                        }
                      />
                      <img
                        src={
                          mobileData?.details[
                            "app-section-app-google-play-logo-url"
                          ]?.url
                        }
                      />
                    </>
                  ) : (
                    <img className={Style.mble} src={Assets.playstore2mble} />
                  )}
                </a>
              )}
            </li>
          </ul>
        )}
      </div>
    </>
  );
};

export default ExchangeContent;
