import React from "react";
import Style from "../CurrencyFold.module.scss";
import Icomoon from "../../Layouts/IcoMooon";

const Shimmer = () => {
  return (
    <div className={Style.form_shimmer}>
      <div className={Style.formsec}>
        <div className={Style.currency_from}>
          <div className={Style.cols}>
            <div className={`input-holder active ${Style.input_holder}`}>
              <label className={Style.form_shimmer_shine} htmlFor="currency_from"></label>
              <span className={Style.form_shimmer_shine}></span>
            </div>
          </div>
          <div className={Style.cols}>
            <div className={`select-holder ${Style.select_holder}`}>
              <label className={Style.form_shimmer_shine}></label>
              <span className={Style.form_shimmer_shine}><span></span></span>
            </div>
          </div>
        </div>

        <div className={Style.currency_btnsec}>
          <button type="button" className={`${Style.form_shimmer_shine} ${Style.convertbtn}`}>
            <span className={Style.icons}>
            </span>
          </button>
        </div>

        <div className={Style.currency_to}>
          <div className={Style.cols}>
            <div className={`input-holder active ${Style.input_holder}`}>
              <label className={Style.form_shimmer_shine} htmlfor="currency_from"></label>
              <span className={Style.form_shimmer_shine}></span>
            </div>
          </div>

          <div className={Style.cols}>
            <div className={`select-holder ${Style.select_holder}`}>
              <label className={Style.form_shimmer_shine}></label>
              <span className={Style.form_shimmer_shine}><span></span></span>
            </div>
          </div>
        </div>

        <div className={Style.btm}>
          <div className={Style.refresh}>
            <span className={Style.form_shimmer_shine}></span>
          </div>
          <div className={Style.rate_btn}>
          <span className={Style.form_shimmer_shine}></span>
          </div>
        </div>
      </div>
      <div className={Style.graphsec}>
        <div className={Style.currency_status}>
          <div className={Style.currency_status_to}>
            <small className={Style.form_shimmer_shine}></small>
            <span className={Style.form_shimmer_shine}></span>
          </div>
          <div className={Style.currency_status_details}>
            <div className={`h6 ${Style.currency_status_time} ${Style.form_shimmer_shine}`}>
              
            </div>
            <div className={`${Style.form_shimmer_shine} ${Style.currency_status_disclaimer}`}>
            </div>
          </div>
        </div>
        <div className={Style.graphwrap}>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Shimmer;
