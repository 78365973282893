import { useEffect } from "react";
import { getTestimonials } from "../store/slices/homeTestimonialSlice";
import { useDispatch, useSelector } from "react-redux";

export const useHomeTestimonial = () => {
  const dispatch = useDispatch();
  const testimonialsData = useSelector((state) => state.testimonials);

  useEffect(() => {
    if (testimonialsData.status === "idle") {
      loader();
    }
  }, []);

  const loader = async () => {
    await dispatch(getTestimonials());
  };

  return {
    testimonialsData: testimonialsData?.testimonialsData,
  };
};
