import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  dynamicData: null,
};

export const getDynamicMenu = createAsyncThunk(
  "dynamic/getDynamicMenu",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`page?slug=${params.dynamicSlug}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const dynamicMenuSlice = createSlice({
  name: "dynamic",
  initialState,
  reducers: {},
  extraReducers: {
    [getDynamicMenu.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getDynamicMenu.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.dynamicData = action.payload?.data?.details;
    },
    [getDynamicMenu.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export default dynamicMenuSlice.reducer;
