import React from "react";
import CommonLayout from "../../components/Layouts/CommonLayout";
import MediaDetail from "../../components/MediaDetail";

const MediaDetailPage = () => {
  return (
    <CommonLayout>
        <MediaDetail />
    </CommonLayout>
  );
};

export default MediaDetailPage;
