import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  branchData: null,
};

export const getBranch = createAsyncThunk(
  "branch/getBranch",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`branches-locations`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);


export const getBranchSearch = createAsyncThunk(
  "branch/getBranchSearch",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`branches/search?s=${params.branchSlug}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const branchLocatorSlice = createSlice({
  name: "branch",
  initialState,
  reducers: {},
  extraReducers: {
    [getBranch.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getBranch.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.branchData = action.payload?.data;
    },
    [getBranch.rejected]: (state, action) => {
      state.status = "failed";
    },


    [getBranchSearch.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getBranchSearch.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.branchData = action.payload?.data;
    },
    [getBranchSearch.rejected]: (state, action) => {
      state.status = "failed";
    },


  },
});

export default branchLocatorSlice.reducer;
