import { useEffect } from "react";
import { getAbout } from "../store/slices/homeAboutUsSlice";
import { useDispatch, useSelector } from "react-redux";

export const useHomeAboutUs = () => {
  const dispatch = useDispatch();
  const aboutData = useSelector((state) => state.about);

  useEffect(() => {
    if (aboutData.status === "idle") {
      dispatch(getAbout());
    }
  },[]);

 

  return {
    aboutData: aboutData?.aboutData,
  };
};
