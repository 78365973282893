import React, { useState } from "react";
import Style from "./CareersDetailShare.module.scss";
import { Link } from "react-router-dom";
import IcoMoon from "../Layouts/IcoMooon";
import { useSelector } from "react-redux";

const CareersDetailShare = ({ single }) => {
  const [isCopied, setIsCopied] = useState(false);

  const { isActive } = useSelector((state) => state.careersJobSingle);

  const copyTextToClipboard = async (text) => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  };

  const handleCopyClick = (copyText) => {
    copyTextToClipboard(copyText)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {});
  };

  return (
    <div
      className={
        isActive
          ? `${Style.sharedetailsec} ${Style.loaded}`
          : Style.sharedetailsec
      }
    >
      <div className="container">
        <div className={`smcontainer`}>
          <div className={Style.wrapper}>
            <div className={`h5 ${Style.titlesec}`}>
              Have A Friend Who Would Love This?
            </div>
            <div>
              <p>Share this with your network</p>
            </div>
            <div className={Style.smedias}>
              <ul>
                <li>
                  <a
                    href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURI(
                      window.location.href
                    )}`}
                    target="_blank"
                    rel="noreferrer" 
                  >
                    <IcoMoon icon="facebook" size={16} />
                  </a>
                </li>
                <li>
                  <a
                    href={`https://twitter.com/intent/tweet?url=${encodeURI(
                      window.location.href
                    )}`}
                    target="_blank"
                    rel="noreferrer" 
                  >
                    <IcoMoon icon="twitter" size={15} />
                  </a>
                </li>
                <li>
                  <a
                    href={`https://www.instagram.com/?url=${encodeURI(
                      window.location.href
                    )}`}
                    target="_blank"
                    rel="noreferrer" 
                  >
                    <IcoMoon icon="insta" size={17} />
                  </a>
                </li>
                <li>
                  <Link
                    to="#"
                    onClick={() => {
                      handleCopyClick(
                        `${window.location.host}/careers-detail/${single?.details[0]?.slug}/${single?.details[0]?.ID}`
                      );
                    }}
                  >
                    <IcoMoon icon="attach" size={14} />
                  </Link>

                  {isCopied && (
                    <span className={Style.clip_to_board}>copied</span>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareersDetailShare;
