import { useEffect } from "react";
import { getHomeBranch } from "../store/slices/homeBranchLocatorSlice";
import { useDispatch, useSelector } from "react-redux";

export const useHomeBranchLocator = () => {
  const dispatch = useDispatch();
  const homeBranchData = useSelector((state) => state.homebranch);

  useEffect(() => {
    if (homeBranchData.status === "idle") {
      dispatch(getHomeBranch());
    }
  },[]);

  return {
    homeBranchData: homeBranchData?.homeBranchData,
  };
};
