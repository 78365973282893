import { useEffect, useState } from "react";
import { getBanner } from "../store/slices/homeBannerSlice";
import { useDispatch, useSelector } from "react-redux";

export const useHomeBanner = () => {
  const dispatch = useDispatch();
  const bannerData = useSelector((state) => state.banner);

  useEffect(() => {
    if (bannerData.status === "idle") {
      dispatch(getBanner());
      document.getElementById("changeLag").classList.remove("noScroll");
    }
  }, []);

  return {
    bannerData: bannerData?.bannerData,
  };
};
