import React from "react";
import CorporateMoneyTransfer from "../../components/CorporateMoneyTransfer";
import CommonLayout from "../../components/Layouts/CommonLayout";

const CorporateMoneyTransferPage = () => {
  return (
    <CommonLayout>
      <CorporateMoneyTransfer />
    </CommonLayout>
  );
};

export default CorporateMoneyTransferPage;
