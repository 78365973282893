import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  testimonialsData: null,
};

export const getTestimonials = createAsyncThunk(
  "testimonials/getTestimonials",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`home/testimonials`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);

    }
  }
);

const homeTestimonialSlice = createSlice({
  name: "testimonials",
  initialState,
  reducers: {},
  extraReducers: {
    [getTestimonials.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getTestimonials.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.testimonialsData = action.payload?.data;
    },
    [getTestimonials.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export default homeTestimonialSlice.reducer;
