import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  aboutBannerData: null,
  aboutCertificationsData:null,
  aboutPartnersData:null,
  isActive:false


};

export const getAboutBanner = createAsyncThunk(
  "pageAbout/getAboutBanner",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`page/about-us/banner`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);

    }
  }
);

export const getAboutCertifications = createAsyncThunk(
  "pageAbout/getAboutcertifications",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`page/about-us/certifications-and-awards`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);

    }
  }
);


export const getAboutPartners = createAsyncThunk(
  "pageAbout/getAboutPartners",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`page/about-us/partners-associates`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);

    }
  }
);

const aboutUsSlice = createSlice({
  name: "pageAbout",
  initialState,
  reducers: {},
  extraReducers: {
    [getAboutBanner.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getAboutBanner.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.aboutBannerData = action.payload?.data;
      state.isActive = true;

    },
    [getAboutBanner.rejected]: (state, action) => {
      state.status = "failed";
    },


    [getAboutCertifications.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getAboutCertifications.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.aboutCertificationsData = action.payload?.data;

    },
    [getAboutCertifications.rejected]: (state, action) => {
      state.status = "failed";
    },


    [getAboutPartners.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getAboutPartners.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.aboutPartnersData = action.payload?.data;

    },
    [getAboutPartners.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export default aboutUsSlice.reducer;
