import React from "react";
import CommonLayout from "../../components/Layouts/CommonLayout";
import ServiceCurrencyExchange from "../../components/ServiceCurrencyExchange";

const ServiceCurrencyExchangePage = () => {
  return (
    <CommonLayout>
      <ServiceCurrencyExchange />
    </CommonLayout>
  );
};

export default ServiceCurrencyExchangePage;
