import { useEffect } from "react";
import { getMobile } from "../store/slices/homeMobAppSlice";
import { useDispatch, useSelector } from "react-redux";

export const useHomeMobApp = () => {
  const dispatch = useDispatch();
  const mobileData = useSelector((state) => state.mobile);

  useEffect(() => {
    if (mobileData.status === "idle") {
      dispatch(getMobile());
    }
  },[]);

 

  return {
    mobileData: mobileData?.mobileData,
  };
};
