import React from "react";
import BranchLocator from "../../components/BranchLocator";
import CommonLayout from "../../components/Layouts/CommonLayout";

const BranchLocatorPage = () => {
    return (
        <CommonLayout>
            <BranchLocator />
        </CommonLayout>
    )
}
export default BranchLocatorPage;