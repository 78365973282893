import { useEffect, useState } from "react";
import { getCareersJobSingle } from "../store/slices/careersJobSingleSlice";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

export const useCareersJobsSingle = () => {
  const [single, setSingle] = useState();

  const { carID } = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    getSingleCareerDetail(carID);
  }, [carID]);

  const getSingleCareerDetail = (carID) => {
    dispatch(getCareersJobSingle({ j_id: carID }))
      .unwrap()
      .then((res) => {
        setSingle(res?.data);
      });
  };

  return {
    single,
  };
};
