import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  mobileData: null,
  activeClass:false
};

export const getMobile = createAsyncThunk(
  "mobile/getMobile",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`home/mobile-app`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);

    }
  }
);

const homeMobAppSlice = createSlice({
  name: "mobile",
  initialState,
  reducers: {},
  extraReducers: {
    [getMobile.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
      state.activeClass=false
    },
    [getMobile.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.mobileData = action.payload?.data;
      state.activeClass=true

    },
    [getMobile.rejected]: (state, action) => {
      state.status = "failed";
      state.activeClass=false

    },
  },
});

export default homeMobAppSlice.reducer;
