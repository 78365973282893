import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  blogCatData: null,
  isActiveClass:false

};

export const getBlogCat = createAsyncThunk(
  "blogCat/getBlogCat",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`home/blog/categories`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const blogCategorySlice = createSlice({
  name: "blogCat",
  initialState,
  reducers: {},
  extraReducers: {
    [getBlogCat.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getBlogCat.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.blogCatData = action.payload?.data;
      state.isActiveClass = true;

    },
    [getBlogCat.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export default blogCategorySlice.reducer;
