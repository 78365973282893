import React from "react";
import Enquire from "../../components/Enquire";
import CommonLayout from "../../components/Layouts/CommonLayout";

const EnquirePage = () => {
  return (
    <CommonLayout>
      <Enquire />
    </CommonLayout>
  );
};

export default EnquirePage;
