import React from "react";
import Style from "./EnquireOtherBranches.module.scss";
import Branches from "../Branches";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const EnquireOtherBranches = () => {
  const { activeClass } = useSelector((state) => state.enquiry);

  return (
    <div
      className={
        activeClass
          ? `${Style.otherbranchesec} ${Style.loaded}`
          : Style.otherbranchesec
      }
    >
      <div className="container">
        <div className={Style.titlesec}>
          <h2>Other Branches</h2>
        </div>
        <Branches />
        <div className={`text-center ${Style.btnwrap}`}>
          <Link to="/our-branches" className={`btn btn-brdr ${Style.btn}`}>
            <span>View All Branches</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default EnquireOtherBranches;
