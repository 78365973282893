import { useEffect } from "react";
import { getCareersJob } from "../store/slices/careersJobSlice";
import { useDispatch, useSelector } from "react-redux";

export const useCareersJobs = (page) => {
  const dispatch = useDispatch();

  const { careersJobData, status } = useSelector((state) => state.careersJob);

  useEffect(() => {
    if (status === "idle") {
      dispatch(getCareersJob({ page: 1, per_page: 1000 }));
    }
  }, []);

  return {
    careersJobData,
  };
};
