import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  enquiryData: null,
  activeClass: false,
};

export const getEnquiry = createAsyncThunk(
  "enquiry/getEnquiry",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();

    try {
      const response = await api.get(`page/enquiry`, { params });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const enquirySlice = createSlice({
  name: "enquiry",
  initialState,
  reducers: {},
  extraReducers: {
    [getEnquiry.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getEnquiry.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.enquiryData = action.payload?.data;
      state.activeClass = true;
    },
    [getEnquiry.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export default enquirySlice.reducer;
