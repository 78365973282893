import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  currentData: null,
};

export const getCurrent = createAsyncThunk(
  "current/getCurrent",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`home/current_openings`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);

    }
  }
);

const homeCurrentOpeningSlice = createSlice({
  name: "current",
  initialState,
  reducers: {},
  extraReducers: {
    [getCurrent.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getCurrent.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.currentData = action.payload?.data;
    },
    [getCurrent.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export default homeCurrentOpeningSlice.reducer;
