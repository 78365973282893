import React from "react";
import Faq from "../../components/Faq";
import CommonLayout from "../../components/Layouts/CommonLayout";

const FaqPage = () => {
  return (
    <CommonLayout>
      <Faq />
    </CommonLayout>
  );
};

export default FaqPage;
