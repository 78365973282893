import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  blogData: null,
};

export const getBlog = createAsyncThunk(
  "blog/getBlog",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`home/blog`, { params });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const blogSlice = createSlice({
  name: "blog",
  initialState,
  reducers: {},
  extraReducers: {
    [getBlog.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getBlog.fulfilled]: (state, action) => {
      state.status = "succeeded";

      const payloadMediaData = action.payload?.data;

      if (payloadMediaData.details.more_details.current_page == 1) {
        state.blogData = payloadMediaData;
      } else {
        state.blogData = {
          details: {
            blog_list: state.blogData.details.blog_list.concat(
              payloadMediaData.details.blog_list
            ),
            total_jobs: payloadMediaData.details.total_jobs,
            more_details: payloadMediaData.details.more_details,
          },
        };
      }
    },
    [getBlog.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export default blogSlice.reducer;
