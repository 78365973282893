import React from "react";
import AboutUs from "../../components/AboutUs";
import CommonLayout from "../../components/Layouts/CommonLayout";

const AboutUsPage = () => {
  return (
    <CommonLayout>
      <AboutUs />
    </CommonLayout>
  );
};

export default AboutUsPage;
