import { useEffect, useState } from "react";
import { getSingleBlog } from "../store/slices/singleBlogSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export const useSingleBlog = () => {
  const { categories_ids, ID } = useParams();

  const [single, setSingle] = useState();

  const dispatch = useDispatch();

  useEffect(() => {
    getSingleBlogDetail(categories_ids, ID);
  }, [categories_ids, ID]);

  const getSingleBlogDetail = (categories_ids, ID) => {
    dispatch(getSingleBlog({ pid: ID, cat_id: categories_ids }))
      .unwrap()
      .then((res) => {
        setSingle(res?.data);
      });
  };

  return {
    single,
  };
};
