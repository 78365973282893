import React from "react";
import Style from "./ModalThanks.module.scss";
import Assets from "../Layouts/Assets";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

const ModalThanks = ({setModalShow}) => {
  return (
    <div className={Style.thankwrap}>
      <div>
        <figure>
          <img src={Assets.thanks_img} alt="" />
        </figure>
        <div className={Style.thanktitle}>
          <h2>Thank you!</h2>
        </div>
        <div className={Style.thankmsg}>
          <p>for initiating your journey with Sharaf Exchange</p>
        </div>
        <div className={Style.visitext}>
          <p>
            Please visit the <Link to="/our-branches">nearest branch</Link> of Sharaf
            Exchange to finish your KYC”
          </p>
        </div>
        <Button className={`btn btn-brdr ${Style.donebtn}`} onClick={() => setModalShow(false)}>
          <span>Done</span>
        </Button>
      </div>
    </div>
  );
};

export default ModalThanks;
