import React from "react";
import Style from './ServiceBannerFold.module.scss';
import AboutBannerFold from "../../AboutBannerFold";
import { useAboutUs } from "../../../logic/useAboutUs";

const ServiceBannerFold = () => {
  const { aboutBannerData } = useAboutUs();

    return (
        <div className={Style.bnrfold}>
          <AboutBannerFold  aboutBannerData={aboutBannerData} />
        </div>
    )
}
export default ServiceBannerFold;