import CommonLayout from "../../components/Layouts/CommonLayout";
import DynamicMenu from "../../components/DynamicMenu";

const DynamicMenuPage = () => {
  return (
    <CommonLayout>
      <DynamicMenu />
    </CommonLayout>
  );
};

export default DynamicMenuPage;
