import React from "react";
import CommonLayout from "../../components/Layouts/CommonLayout";
import Careers from "../../components/Careers";

const CareersPage = () => {
  return (
    <CommonLayout>
      <Careers />
    </CommonLayout>
  );
};

export default CareersPage;
