import { useEffect } from "react";
import { getEnquiry } from "../store/slices/enquirySlice";
import { useDispatch, useSelector } from "react-redux";

export const useEnquiry = () => {
  const dispatch = useDispatch();
  const enquiryData = useSelector((state) => state.enquiry);

  useEffect(() => {
    if (enquiryData.status === "idle") {
      dispatch(getEnquiry({ slug: "enquire" }));
    }
  }, []);

  return {
    enquiryData: enquiryData?.enquiryData,
  };
};
