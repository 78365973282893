import React from "react";
import Helmet from "react-helmet";

const TitleComponent = ({ title, description }) => {
  var defaultTitle =
    "Top Currency Exchange Company in Dubai | Sharaf Exchange ";

  return (
    <Helmet>
      <title>{title ? title : defaultTitle}</title>
      <description>{description ? description : defaultTitle}</description>
    </Helmet>
  );
};

export { TitleComponent };
