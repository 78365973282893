import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import IcoMoon from "../../Layouts/IcoMooon";
import Style from "./CareerJobMobileView.module.scss";

const CareerJobMobileView = ({
  filteredJobs,
  careersJobData,
  selectedDep,
  selectedLoc,
}) => {
  const [page, setPage] = useState(1);
  const [pageFilter, setPageFilter] = useState([]);

  useEffect(() => {
    if (careersJobData?.details?.jobs) {
      let tempData = filteredJobs.slice(0, page * 2);
      setPageFilter(tempData);
    }
  }, [filteredJobs, page]);

  const loadMoreclick = (pages) => {
    let tempData = filteredJobs.slice(0, pages * 2);
    setPageFilter(tempData);
    setPage(pages);
  };

  useEffect(() => {
    setPage(1);
  }, [selectedDep, selectedLoc]);
  return (
    <div className={Style.joblistmobview}>
      {pageFilter?.length == 0 ? (
        <div className="text-center my-2">No Jobs Found</div>
      ) : (
        pageFilter?.map((value, i) => {
          return (
            <Link
              to={`/careers-detail/${value?.slug}/${value?.ID}`}
              className={Style.resultitem}
              key={i}
            >
              <div className={Style.rolename}>
                {value?.role}{" "}
                <div className={Style.desig}>
                  {value?.department}&nbsp;/&nbsp;{value?.location}
                </div>
              </div>
              <IcoMoon icon="swiper-next" size={16} />
            </Link>
          );
        })
      )}

      {Math.ceil(filteredJobs?.length / 2) > page && (
        <Button
          className={`btn btn-brdr ${Style.btn}`}
          onClick={() => loadMoreclick(page + 1)}
        >
          <span>Load More</span>
        </Button>
      )}
    </div>
  );
};

export default CareerJobMobileView;
