import React from "react";
import Style from "../CertificationsTab.module.scss";
import Certificates from "../Certificates";
import ReactHtmlParser from "react-html-parser";

const CertificationsTabResults = ({ filteredIndex }) => {
  return (
    <div className={Style.resultwrapper}>
      {filteredIndex != null && (
        <div
          className={`${Style.result_item}`}
          key={filteredIndex?.["certifications-and-awards-image-url"]?.id}
        >
          <Certificates filteredIndex={filteredIndex} />
          <h3 className={Style.itemtitle}>
            {ReactHtmlParser(
              filteredIndex?.["certifications-and-awards-title"]
            )}
          </h3>
          <div className={Style.shortcnt}>
            <p>
              {ReactHtmlParser(
                filteredIndex?.["certifications-and-awards-description"]
              )}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default CertificationsTabResults;
