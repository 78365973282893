import React from "react";
import Style from "./Footer.module.scss";
import FooterContact from "./FooterContact";
import FooterMenus from "./FooterMenus";
import FooterBottom from "./FooterBottom";
import { useFooter } from "../../../logic/useFooter";

const Footer = () => {
  const {} = useFooter();

  return (
    <div className={Style.sitefooter}>
      <div className="container">
        <FooterContact />
        <FooterMenus />
        <FooterBottom />
      </div>
    </div>
  );
};

export default Footer;
