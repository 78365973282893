import React from "react";
import CommonLayout from "../../components/Layouts/CommonLayout";
import TermAndConditions from "../../components/TermAndConditions";

const TermAndConditionsPage = () => {
  return (
    <CommonLayout>
      <TermAndConditions />
    </CommonLayout>
  );
};

export default TermAndConditionsPage;
