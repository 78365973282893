import React from 'react'
import Style from './ProgressLoader.module.scss';

const ProgressLoader = () => {
  return (
    <div className={Style.progress_loader}>
        <div className={Style.linear_progress_material}>
            <div className={`${Style.bar} ${Style.bar1}`}></div>
            <div className={`${Style.bar} ${Style.bar2}`}></div>
        </div>
    </div>
  )
}

export default ProgressLoader