import React from "react";
import { Link } from "react-router-dom";
import Assets from "../Layouts/Assets";
import Style from './Error.module.scss';

const Error = () => {
  return (
    <div className={Style.errorpagesec}>
      <div className="container">
        <div className={Style.wrapper}>
          <div className={Style.figwrap}>
            <span className={Style.erorcircle}></span>
            <figure className={Style.imgwrap}>
              <img src={Assets.error_img} alt="Error"/>
            </figure>
          </div>
          <div className={Style.notes}>
            <h1>Sorry, There’s Nothing Here :(</h1>
            <Link to={'/'} className={`btn btn-brdr ${Style.back_btn}`}><span>Take Me Home</span></Link>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Error;
