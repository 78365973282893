import React from "react";

import Style from '../Media.module.scss';

const MediaShimmer = () => {
  return (
    <>
      <div className={Style.media_shimmer}>
        <div className={Style.newitem}>
          <figure className={Style.postimg}>
            <span className={Style.media_shimmer_el}></span>
          </figure>
          <div className={Style.details}>
            <div className={Style.postype}>
              <span className={Style.media_shimmer_el}></span>
            </div>
            <header className={Style.postitle}>
              <span className={Style.media_shimmer_el}></span>
            </header>
            <div className={Style.cntsec}>
              <span className={Style.media_shimmer_el}></span>
            </div>

            <div className={Style.postdate}>
              <span className={Style.media_shimmer_el}></span>
            </div>
          </div>
        </div>
      </div><div className={Style.media_shimmer}>
        <div className={Style.newitem}>
          <figure className={Style.postimg}>
            <span className={Style.media_shimmer_el}></span>
          </figure>
          <div className={Style.details}>
            <div className={Style.postype}>
              <span className={Style.media_shimmer_el}></span>
            </div>
            <header className={Style.postitle}>
              <span className={Style.media_shimmer_el}></span>
            </header>
            <div className={Style.cntsec}>
              <span className={Style.media_shimmer_el}></span>
            </div>

            <div className={Style.postdate}>
              <span className={Style.media_shimmer_el}></span>
            </div>
          </div>
        </div>
      </div><div className={Style.media_shimmer}>
        <div className={Style.newitem}>
          <figure className={Style.postimg}>
            <span className={Style.media_shimmer_el}></span>
          </figure>
          <div className={Style.details}>
            <div className={Style.postype}>
              <span className={Style.media_shimmer_el}></span>
            </div>
            <header className={Style.postitle}>
              <span className={Style.media_shimmer_el}></span>
            </header>
            <div className={Style.cntsec}>
              <span className={Style.media_shimmer_el}></span>
            </div>

            <div className={Style.postdate}>
              <span className={Style.media_shimmer_el}></span>
            </div>
          </div>
        </div>
      </div><div className={Style.media_shimmer}>
        <div className={Style.newitem}>
          <figure className={Style.postimg}>
            <span className={Style.media_shimmer_el}></span>
          </figure>
          <div className={Style.details}>
            <div className={Style.postype}>
              <span className={Style.media_shimmer_el}></span>
            </div>
            <header className={Style.postitle}>
              <span className={Style.media_shimmer_el}></span>
            </header>
            <div className={Style.cntsec}>
              <span className={Style.media_shimmer_el}></span>
            </div>

            <div className={Style.postdate}>
              <span className={Style.media_shimmer_el}></span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MediaShimmer;
