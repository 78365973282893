import { useEffect } from "react";
import {
  getAboutBanner,
  getAboutCertifications,
  getAboutPartners,
} from "../store/slices/aboutUsSlice";
import { useDispatch, useSelector } from "react-redux";

export const useAboutUs = () => {
  const dispatch = useDispatch();
  const aboutBannerData = useSelector((state) => state.pageAbout);
  const aboutCertificationsData = useSelector((state) => state.pageAbout);
  const aboutPartnersData = useSelector((state) => state.pageAbout);

  useEffect(() => {
    if (aboutBannerData.status === "idle") {
      banner();
    }
    if (aboutCertificationsData.status === "idle") {
      certifications();
    }
    if (aboutPartnersData.status === "idle") {
      partners();
    }
  }, []);

  const certifications = async () => {
    await dispatch(getAboutCertifications({}));
  };

  const banner = async () => {
    await dispatch(getAboutBanner({}));
  };

  const partners = async () => {
    await dispatch(getAboutPartners({}));
  };

  return {
    aboutBannerData: aboutBannerData?.aboutBannerData,
    aboutCertificationsData: aboutCertificationsData?.aboutCertificationsData,
    aboutPartnersData: aboutPartnersData?.aboutPartnersData,
  };
};
