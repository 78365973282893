import React from "react";

export const Spinner = () => {
  return (
    <div className="spinner">
      <div className="spinner_1"></div>
      <div className="spinner_2"></div>
      <div className="spinner_3"></div>
    </div>
  );
};
