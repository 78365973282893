import { useEffect } from "react";
import { getMenu } from "../store/slices/menuSlice";
import { useDispatch, useSelector } from "react-redux";

export const useHeader = () => {
  const dispatch = useDispatch();
  const menuData = useSelector((state) => state.menu);

  useEffect(() => {
    if (menuData.status === "idle") {
      menuHeader();
    }
  });

  const menuHeader = async () => {
    await dispatch(getMenu());
  };

  return {
    menuData: menuData?.menuData,
  };
};
