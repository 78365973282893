import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Style from "./CareersDesignationView.module.scss";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useSelector } from "react-redux";
gsap.registerPlugin(ScrollTrigger);

const CareersDesignationView = ({ applyNowRef,single }) => {
  const ovalshapeanim = useRef(),
    ovalshapetwoanim = useRef(),
    roundedrecanim = useRef(),
    circleshapeanim = useRef();

  const { status, isActive } = useSelector((state) => state.careersJobSingle);

  useEffect(() => {
    gsap.to(ovalshapeanim.current, {
      x: -500,
      y: 1500,
      duration: 0.5,
      delay: 0.4,
      width: 300,
      height: 300,
      opacity: 0.7,
      filter: "blur(0)",
      scrollTrigger: {
        scrub: 2,
      },
    });
    gsap.to(ovalshapetwoanim.current, {
      x: -40,
      y: -100,
      duration: 0.2,
      delay: 0.4,
      width: "80%",
      height: "90%",
      opacity: 0.15,
      scrollTrigger: {
        scrub: 2,
      },
    });
    gsap.to(roundedrecanim.current, {
      x: -40,
      y: -100,
      duration: 0.2,
      delay: 0.4,
      opacity: 0.15,
      scrollTrigger: {
        scrub: 2,
      },
    });
    gsap.to(circleshapeanim.current, {
      x: 100,
      y: -900,
      duration: 0.5,
      delay: 0.4,
      scrollTrigger: {
        scrub: 2,
      },
    });
  });

  const handleScrollToPosition = () => {
    let elem = document.querySelector(`.applynowsec`);

    window.scrollTo({
      top: elem.getBoundingClientRect().top - 150,
      left: 0,
      behavior: "smooth",
    });
  };


  

  return (
    <div
      className={
        isActive
          ? `${Style.designationview} ${Style.loaded}`
          : Style.designationview
      }
    >
      <span className={Style.ovalshape_left} ref={ovalshapeanim}></span>
      <div className="container">
        <div className={Style.wrapper}>
          <span className={Style.ovalshape} ref={ovalshapetwoanim}></span>
          <span className={Style.roundedrec} ref={roundedrecanim}></span>
          <span className={Style.circleshape} ref={circleshapeanim}></span>
          <div className={Style.detailwrap}>
            {status === "loading" ? (
              <div>
                <h1 className={`shine`}></h1>
                <div className={`shine ${Style.shinedesig}`}></div>
                <div className={`shine ${Style.shinepostdetail}`}></div>
              </div>
            ) : (
              <div>
                <h1>{single?.details[0]?.role}</h1>
                <div className={Style.desigdetail}>
                  <span>{single?.details[0]?.department}</span> /{" "}
                  <span>
                    {
                      single?.details[0]?.more_details?.[
                        "work-type"
                      ]
                    }
                  </span>
                  /<span>{single?.details[0]?.location}</span>{" "}
                </div>
                {single?.details[0]?.author && (
                  <div className={Style.postdetail}>
                    Posted By: {single?.details[0]?.author}
                  </div>
                )}
              </div>
            )}
            <div>
              <Button
                className={`btn btn-brdr ${Style.applynow}`}
                onClick={() => handleScrollToPosition()}
              >
                <span>Apply Now</span>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareersDesignationView;
