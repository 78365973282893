import React from "react";
import CommonLayout from "../../components/Layouts/CommonLayout";
import Media from "../../components/Media";

const MediaPage = () => {
  return (
    <CommonLayout>
      <Media />
    </CommonLayout>
  );
};

export default MediaPage;
