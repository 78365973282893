import { useEffect } from "react";
import {  getFormKey } from "../store/slices/formSlice";
import { useDispatch, useSelector } from "react-redux";


export const useForm = () => {
  const dispatch = useDispatch();
  const { formKey } = useSelector((state) => state.form);


  useEffect(() => {
    key()
  }, []);

  const key=async()=>{
    await dispatch(getFormKey());
  }

  return {formKey};
};
