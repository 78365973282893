import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  branchesData: null,
  nearestBranchData: null,
};

export const getBranches = createAsyncThunk(
  "branches/getBranches",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`branches`, { params });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getNearestBranch = createAsyncThunk(
  "branches/getNearestBranch",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`get-nearest-branches`, { params });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const branchesSlice = createSlice({
  name: "branches",
  initialState,
  reducers: {},
  extraReducers: {
    [getBranches.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getBranches.fulfilled]: (state, action) => {
      state.status = "succeeded";
      const payload = action.payload?.data;

      if (payload[0]?.code === "000") {
        const objectData = {
          details: [],
        };
        state.branchesData = objectData;
        return;
      }

      if (payload.more_details.current_page == 1) {
        state.branchesData = payload;
      } else {
        state.branchesData = {
          details: state.branchesData.details.concat(payload.details),
          more_details: payload.more_details,
        };
      }
    },
    [getBranches.rejected]: (state, action) => {
      state.status = "failed";
    },

    [getNearestBranch.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getNearestBranch.fulfilled]: (state, action) => {
      state.status = "succeeded";
      const payload = action.payload?.data;

      if (payload[0]?.code === "000") {
        const objectData = {
          details: [],
        };
        state.branchesData = objectData;
        return;
      }

      if (payload.more_details.current_page == 1) {
        state.branchesData = payload;
      } else {
        state.branchesData = {
          details: state.branchesData.details.concat(payload.details),
          more_details: payload.more_details,
        };
      }
    },
    [getNearestBranch.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export default branchesSlice.reducer;
