import React, { useEffect } from "react";
import CommonLayout from "../../components/Layouts/CommonLayout";
import Home from "../../components/Home";
import { useLocation } from "react-router-dom";

const HomePage = () => {
  const location = useLocation();
  useEffect(() => {
    location.pathname === "/" ? document.body.classList.add('homepage') : document.body.classList.remove('homepage');
    return () => {
      document.body.classList.remove('homepage');
    };
  }, [location.pathname]);
  
  return (
    <CommonLayout>
      <Home />
    </CommonLayout>
  );
};

export default HomePage;
