import { useEffect } from "react";
import { getFaq } from "../store/slices/faqSlice";
import { useDispatch, useSelector } from "react-redux";

export const useFaq = () => {
  const dispatch = useDispatch();
  const faqData = useSelector((state) => state.faq);

  useEffect(() => {
    if (faqData.status === "idle") {
      dispatch(getFaq());
    }
  }, []);

  return {
    faqData: faqData?.faqData,
  };
};
