import React, { useEffect, useRef } from "react";
import Assets from "../../Layouts/Assets";
import Style from "../MoneyExchange.module.scss";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import {useDimensions} from '../../../logic/Dimensions';

gsap.registerPlugin(ScrollTrigger);

const ExchangeImages = () => {
  const animtrigger = useRef();
  const {width} = useDimensions();
  useEffect(() => {
    const bnrappview = gsap.timeline({
      scrollTrigger: {
        trigger: `.${Style.imgsec}`,
        scrub: 2,
      },
    });
    if (width >=1200)  {
      bnrappview
        .to(`.${Style.shapewrap}`, {
          scrub: 2,
          y: -23,
          x: -20,
        })
        .to(`.${Style.imgwrap}`, {
          scrub: 2,
          rotation: -4,
          x: -30,
        });
    }
  });

  return (
    <div className={Style.imgsec} ref={animtrigger}>
      <div className={`${Style.appviewrap}`}>
        <figure className={Style.imgwrap}>
          <img src={Assets.exchangeimg01} alt="" />
          <div className={Style.videoplay}>
            <video
              loop
              muted
              autoPlay={true}
              controls=""
              className={Style.videotag}
              playsInline
            >
              <source src={Assets.bnrvideo} type="video/mp4" />
            </video>
          </div>
        </figure>
        <div className={Style.shapewrap}>
          <span className={`${Style.shapes} ${Style.shapes_01}`}>$</span>
          <span className={`${Style.shapes} ${Style.shapes_02}`}>AED</span>
          <span className={`${Style.shapes} ${Style.shapes_03}`}>₹</span>
          <span className={`${Style.shapes} ${Style.shapes_04}`}>£</span>
        </div>
      </div>
    </div>
  );
};

export default ExchangeImages;
