import { useEffect, useState } from "react";
import { getBranch, getBranchSearch } from "../store/slices/branchLocatorSlice";
import { useDispatch, useSelector } from "react-redux";

export const useBranchLocator = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchData, setSearchData] = useState([]);

  const dispatch = useDispatch();
  const branchData = useSelector((state) => state.branch);

  useEffect(() => {
    if (branchData.status === "idle") {
      dispatch(getBranch());
    }
  }, []);

  const searchHandleChange = (e) => {
    setSearchTerm(e.target.value);
  };



  return {
    branchData: branchData?.branchData,
    searchData,
    setSearchData,
    searchTerm,
    setSearchTerm,
    searchHandleChange,
    
  };
};
