import React from "react";
import Style from "./CareersDetailApplyNow.module.scss";
import ApplyNowForm from "../ApplyNowForm";
import { useSelector } from "react-redux";

const CareersDetailApplyNow = ({ applyNowRef }) => {
  const { isActive } = useSelector((state) => state.careersJobSingle);

  return (
    <div
      
      className={`${isActive ? Style.loaded : ""} ${Style.applynowsec} applynowsec`}
    >
      <div className="container ">
        <div className={`smcontainer ${Style.wrapper}`}>
          <div className={Style.sectitle}>
            <h2>Apply Now</h2>
          </div>
          <div className={Style.applynowform}>
            <ApplyNowForm applyNowRef={applyNowRef} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CareersDetailApplyNow;
