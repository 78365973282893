import React from 'react'
import Style from '../AllBranch.module.scss';

const BranchShimmer = () => {
  return (
    <div className={`${Style.locitem} ${Style.bracnhes_shimmer}`}>
    <header className={Style.locname}>
        <span className={Style.bracnhes_shimmer_el}></span>
    </header>
    <ul>
    <li className={Style.addr}>
        <span className={Style.bracnhes_shimmer_el}></span>
        <span className={Style.bracnhes_shimmer_el}></span>
        <span className={Style.bracnhes_shimmer_el}></span>
    </li>
    <li className={Style.mob}>
        <span className={Style.bracnhes_shimmer_el}></span>
    </li>
    <li className={Style.date_time}>
        <span className={Style.bracnhes_shimmer_el}></span>
        <span className={Style.bracnhes_shimmer_el}></span>
    </li>
    </ul>
    <div className={Style.arw_btn}>
        <span className={Style.bracnhes_shimmer_el}></span>
    </div>
</div>
  )
}

export default BranchShimmer