import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  menuData: null,
  isActive:false
};

export const getMenu = createAsyncThunk(
  "menu/getMenu",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();

    try {
      const response = await api.get(`site_menu_nav/header`);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const menuSlice = createSlice({
  name: "menu",
  initialState,
  reducers: {},
  extraReducers: {
    [getMenu.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getMenu.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.menuData = action.payload?.data;
      state.isActive = true;
    },
    [getMenu.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export default menuSlice.reducer;
