import React from "react";
import BulkCashDepartment from "../../components/BulkCashDepartment";
import CommonLayout from "../../components/Layouts/CommonLayout";

const BulkCashDepartmentPage = () => {
  return (
    <CommonLayout>
      <BulkCashDepartment />
    </CommonLayout>
  );
};

export default BulkCashDepartmentPage;
