import { useEffect } from "react";
import { getFooter } from "../store/slices/footerSlice";
import { useDispatch, useSelector } from "react-redux";

export const useFooter = () => {
  const dispatch = useDispatch();
  const footerData = useSelector((state) => state.footer);
  useEffect(() => {
    if (footerData.status === "idle") {
      menuFooter();
    }
  });

  const menuFooter = async () => {
    await dispatch(getFooter());
  };

  return {
    footerData: footerData?.footerData,
  };
};
