import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  termsData: null,
};

export const getTerms = createAsyncThunk(
  "terms/getTerms",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`page`,{params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const termsAndConditionsSlice = createSlice({
  name: "terms",
  initialState,
  reducers: {},
  extraReducers: {
    [getTerms.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getTerms.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.termsData = action.payload?.data;
    },
    [getTerms.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export default termsAndConditionsSlice.reducer;
