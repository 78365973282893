import React, { useRef } from "react";
import { Link } from "react-router-dom";

import { useCareersJobsSingle } from "../../logic/useCareersJobsSingle";

import CareersDesignationView from "../CareersDesignationView";
import CareersDetailApplyNow from "../CareersDetailApplyNow";
import CareersDetailRelatedJobs from "../CareersDetailRelatedJobs";
import CareersDetailRequirements from "../CareersDetailRequirements";
import CareersDetailShare from "../CareersDetailShare";
import CareersDetailWhatYoullBeDoing from "../CareersDetailWhatYoullBeDoing";

const CareersDetail = () => {
  const applyNowRef = useRef(null);
  const { single } = useCareersJobsSingle();

  return (
    <div>
      <div className="container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={"/"}>Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to={"/careers"}>Career</Link>
            </li>
            <li className={`breadcrumb-item active`}>
              <span>{single?.details[0]?.role}</span>
            </li>
          </ol>
        </nav>
      </div>
      <CareersDesignationView applyNowRef={applyNowRef} single={single} />
      <CareersDetailWhatYoullBeDoing single={single} />
      <CareersDetailRequirements single={single} />
      <CareersDetailApplyNow applyNowRef={applyNowRef} />
      <CareersDetailShare single={single} />
      <CareersDetailRelatedJobs single={single} />
    </div>
  );
};

export default CareersDetail;
