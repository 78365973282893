import { useEffect } from "react";
import { getCurrencyTableRates } from "../store/slices/currencyRatesSlice";
import { useDispatch, useSelector } from "react-redux";

export const useCurrencyTableRates = () => {
  const dispatch = useDispatch();
  const currencyTableRatesData = useSelector((state) => state.currencyRates);

  useEffect(() => {
    dispatch(getCurrencyTableRates());
  }, []);

  return {
    currencyTableRatesData: currencyTableRatesData?.currencyTableRatesData,
  };
};
