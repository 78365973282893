import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  singleBlogData: null,
  isActive:false,
};

export const getSingleBlog = createAsyncThunk(
  "singleBlog/getSingleBlog",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`home/blog/single`,{params});
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const blogSlice = createSlice({
  name: "singleBlog",
  initialState,
  reducers: {},
  extraReducers: {
    [getSingleBlog.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getSingleBlog.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.singleBlogData = action.payload?.data;
      state.isActive = true;

    },
    [getSingleBlog.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export default blogSlice.reducer;
