import { useEffect } from "react";
import { getDynamicMenu } from "../store/slices/dynamicMenuSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

export const useDynamicMenu = () => {
  const dispatch = useDispatch();
  const { dynamicData } = useSelector((state) => state.dynamic);

  const { dynamicMenu } = useParams();

  useEffect(() => {
    dispatch(getDynamicMenu({ dynamicSlug: dynamicMenu }));
  }, [dynamicMenu]);

  return {
    dynamicData,
  };
};
