import React, { useEffect } from "react";
import Style from "./UsrariBenefit.module.scss";
import { useSelector } from "react-redux";
import ServiceContentTwo from "../ServiceContentSection/ServiceContentTwo";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useLocation } from "react-router-dom";
gsap.registerPlugin(ScrollTrigger);

const UsratiBenefit = () => {
  useEffect(() => {
    gsap.fromTo(
      `.${Style.usratibenefitsec} img`,
      {
        scale: 1.2,
        scrollTrigger: {
          trigger: `.${Style.usratibenefitsec} img`,
          scrub: 1.2,
        },
      },
      {
        scale: 1,
        scrollTrigger: {
          trigger: `.${Style.usratibenefitsec} img`,
          scrub: 1.2,
        },
      },
      0.2
    );
  });

  const location = useLocation();

  const pageService = useSelector((state) => state.pageService);

  let slug = location?.pathname.split("/")[2];

  let data =
    pageService?.[slug]?.details[0] &&
    pageService?.[slug]?.details[0]?.page_sections?.[
      "page-details-section-one"
    ]?.["page-details-section-one-items"]?.[1];

  return (
    <div
      className={
        pageService.loaderClass
          ? `${Style.usratibenefitsec} ${Style.loaded}`
          : Style.usratibenefitsec
      }
    >
      <div className="container">
        <div className={Style.wrapper}>
          <div className={Style.imgcol}>
            <div className={Style.figwrapper}>
              <figure className={Style.imgwrap}>
                <img
                  src={data?.["page-details-section-one-image"]?.url}
                  alt=""
                />
              </figure>
            </div>
          </div>
          <div className={Style.textcol}>
            <ServiceContentTwo />
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsratiBenefit;
