import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  careersJobData: null,
};

export const getCareersJob = createAsyncThunk(
  "careersJob/getCareersJob",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `page/career/jobs?l_id=${params.l_id}&d_id=${params.d_id}&page=${params.page}&posts_per_page=${params.per_page}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const careersJobSlice = createSlice({
  name: "careersJob",
  initialState,
  reducers: {},
  extraReducers: {
    [getCareersJob.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getCareersJob.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.careersJobData = action.payload?.data;
    },
    [getCareersJob.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export default careersJobSlice.reducer;
