import React from "react";
import Error from "../../components/Error";
import Menu from "../../components/Layouts/Menu";

const ErrorPage = () => {
  return (
    <div>
      <Menu />
      <Error />
    </div>
  );
};

export default ErrorPage;
