import React from "react";
import CommonLayout from "../../components/Layouts/CommonLayout";
import Usrati from "../../components/Usrati";

const UsratiPage = () => {
  return (
    <CommonLayout>
      <Usrati />
    </CommonLayout>
  );
};

export default UsratiPage;
