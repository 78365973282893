import React from "react";
import CareersDetail from "../../components/CareersDetail";
import CommonLayout from "../../components/Layouts/CommonLayout";

const CareersDetailPage = () => {
  return (
    <CommonLayout>
      <CareersDetail />
    </CommonLayout>
  );
};

export default CareersDetailPage;
