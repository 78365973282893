import React from "react";
import BuyBackGuarantee from "../../components/BuyBackGuarantee";
import CommonLayout from "../../components/Layouts/CommonLayout";

const BuyBackGuaranteePage = () => {
  return (
    <CommonLayout>
      <BuyBackGuarantee />
    </CommonLayout>
  );
};

export default BuyBackGuaranteePage;
