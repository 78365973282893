import React from "react";
import CommonLayout from "../../components/Layouts/CommonLayout";
import RetailMoneyTransfer from "../../components/RetailMoneyTransfer";

const RetailMoneyTransferPage = () => {
  return (
    <CommonLayout>
      <RetailMoneyTransfer />
    </CommonLayout>
  );
};

export default RetailMoneyTransferPage;
